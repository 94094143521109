export default {
  dashboard: "dashboard",
  events: "events",
  providers: "providers",
  users: "users",
  admins: "admins",
  add_admin: "add admin",
  logout: "logout",
  total_events: "total events",
  total_providers: "total providers",
  total_users: "total users",
  top_events: "top events",
  image: "image",
  address: "address",
  provider_name: "provider name",
  event_name: "event name",
  event_type: "event type",
  star_name: "star name",
  event_date: "event date",
  seats_count: "seats count",
  seat_price: "seat price",
  booked_seats: "booked seats",
  attended_count: "attended count",
  provider: "provider",
  filter: "filter",
  add_category: "add category",
  add_new_event: "add new event",
  add_new_category: "add new category",
  category_name: "category name",
  submit: "submit",
  description: "description",
  search_places: "search places",
  edit_event: "edit event",
  join_date: "join date",
  employers: "employers",
  add_new_provider: "add new provider",
  name: "name",
  email: "email",
  phone: "phone",
  password: "password",
  edit_provider: "edit provider",
  update: "update",
  no_employers: "no employers",
  user: "user",
  user_name: "user name",
  edit_user: "edit user",
  admin_email: "admin email",
  add_admin: "add admin",
  booked_events: "booked events",
  language: "Language",
  english: "english",
  arabic: "arabic",
  events_count: "events count",
  staff_count: "staff count",
  live_events: "live events",
  end_events: "end events",
  back: "back",
  provider_events: "provider_events",
  add_seats: "add seats",
  add_new_seats: "add new seats",
  seat_name: "seat name",
  seat_count: "seat count",
  seat_type: "seat type",
  display_details: "display",
  tickets_info: "tickets information",
  charge_id: "Ticket number",
  date: "ticket date",
  numberOfSeats: "number of seats",
  paidAmount: "paid",
  price: "price",
  fees: "fees",
  scaned: "attended",
  seatType: "seat type",
  available_seats: "availabe seats",
  event_details: "event details",
  booking_details: "booking details",
  event_adress: "event adress",
  special_seat: "special seat",
  vip_seat: "vip seat",
  book_tickets: "book tickets",
  download_qr: "download qr code",
  promocodes: "promocodes",
  empty: "",
  expiry_date: "Expiry date",
  ticket_name: "Ticket name",
  new_event: "new event",
};
