/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-dupe-keys */
export default {
  dashboard: "لوحة التحكم",
  events: "الحفلات",
  providers: "الناشرين",
  users: "المستخدمين",
  admins: "المشرفين",
  add_admin: "اضافة مشرف",
  logout: "تسجيل خروج",
  total_events: "اجمالي الحفلات",
  total_providers: "اجمالي الناشرين",
  total_users: "اجمالي المستخدمين",
  top_events: "أعلى الحفلات",
  address: "العنوان",
  image: "الصورة",
  provider_name: "اسم الناشر",
  event_name: "اسم الفعالية",
  event_type: "نوع الحفلة",
  star_name: "اسم النجم ",
  event_date: "تاريخ الفعالية",
  seats_count: "عدد التذاكر",
  seat_price: "ثمن المقعد",
  booked_seats: "المقاعد المحجوزة",
  attended_count: "عدد الحضور",
  provider: "الناشر",
  filter: "تصفية",
  add_category: "اضافة تصنيفات",
  add_new_event: "اضافة حفلة جديدة ",
  add_new_category: "اضافة تصنيف جديد",
  category_name: "اسم التصنيف",
  submit: "تسجيل",
  description: "الوصف",
  search_places: "بحث الاماكن",
  edit_event: "تعديل الحفلة",
  join_date: "تسجيل التاريخ",
  employers: "الموظفين",
  add_new_provider: "تسجيل ناشر جديد",
  name: "الاسم",
  email: "الايميل",
  phone: "الموبايل",
  password: "كلمة السر",
  edit_provider: "تعديل الناشر",
  update: "تحديث",
  no_employers: "لا يوجد موظفين",
  user: "المستخدم",
  user_name: "اسم المستخدم",
  edit_user: "تعديل المستخدم",
  admin_email: "ايميل المشرف",
  add_admin: "اضافة مشرف",
  booked_events: "الحفلات المحجوزة",
  language: "اللغة",
  english: "الانجليزية",
  arabic: "العربية",
  events_count: "اجمالي الحفلات",
  staff_count: "اجمالي الموظفين",
  live_events: "الحفلات الحالية",
  end_events: "الحفلات المنتهية",
  back: "عودة",
  provider_events: "حفلات الناشر",
  add_seats: "اضافة كراسي",
  add_new_seats: "اضافة كراسي جديدة",
  seat_name: "اسم الكرسي",
  seat_count: "عدد الكراسي",
  seat_type: "نوع الكرسي",
  display_details: "عرض",
  tickets_info: "معلومات التذاكر",
  charge_id: "رقم التذكرة",
  date: "تاريخ حجز التذكرة",
  numberOfSeats: "عدد الكراسي",
  paidAmount: "المدفوع",
  price: "الثمن",
  fees: "الرسوم",
  scaned: "تم الحضور",
  seatType: "نوع الكرسي",
  availabe_seats: "الكراسي المتاحة",
  event_details: "تفاصيل الحفلة",
  booking_details: "تفاصيل الحجز",
  event_adress: "عنوان الفعالية",
  special_seat: "كراسي خاصة",
  vip_seat: "كراسي VIP",
  book_tickets: "حجز التذاكر",
  download_qr: "تحميل رمز الاستخدام",
  promocodes: "كود الخصم",
  empty: "",
  expiry_date: "تاريخ الانتهاء",
  ticket_name: "اسم التذكرة",
  new_event: "حفلة جديدة",
};
