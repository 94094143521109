import { Flex } from "@chakra-ui/core";
import Map from "../googlemap";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import PreviewMultipleImages from "../multi-imageInput/PreviewMultipleImages";
import { useContext } from "react";
import AppContext from "../../context/appContext";
import axios from "axios";
import EventSeatsType from "./EventSeatsType";

const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

const EventDetails = ({ reduxState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    isVenue,
    allSeats,
    seatsAvailable,
    rows,
    columns,
    topObjects,
    bottomObjects,
    rightObjects,
    leftObjects,
    seats,
    topTables,
    bottomTables,
    rightTables,
    leftTables,
    types,
  } = useContext(AppContext);

  const [categories, setCategories] = useState([]);

  const [eventDetails, setEventDetails] = useState({
    name: "",
    starName: "",
    category: "",
    description: "",
    startDate: "",
    endDate: "",
    recurring: false,
    numberOfSeats: 0,
    address: "",
    lat: 0,
    lng: 0,
    timeSlots: [],
    imageUrl: [],
    numberOfAvailableSeats: 0,
    currency: "SAR",
    provider_id: "",
    provider_name: "",
    venueMap: {},
    allSeats: [],
    seatsAvailable: 0,
    ticketName: "",
    taxRatio: 0,
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [timeSlot, setTimeSlot] = useState({
    startTime: "",
    endTime: "",
  });

  const [location, setLocation] = useState();
  const [marker, setMarker] = useState();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    // code
    const fetchCategories = async () => {
      const res = await axios.get(`${BASE_SERVER}/category`);
      console.log("res", res);
      setCategories(res.data.categories);
      setEventDetails({
        ...eventDetails,
        category: res.data.categories[0],
      });
    };

    fetchCategories();
  }, []);

  const addSlot = () => {
    setEventDetails({
      ...eventDetails,
      timeSlots: [...eventDetails.timeSlots, timeSlot],
    });
    setTimeSlot({
      startTime: "",
      endTime: "",
    });
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const getUploadedFiles = (files) => {
    setFiles(files);
    setEventDetails({
      ...eventDetails,
      imageUrl: files,
    });
  };

  const handleAddEvent = async () => {
    if (eventDetails.name === "") {
      setError("Please enter event name");
      return;
    }
    if (eventDetails.starName === "") {
      setError("Please enter star name");
      return;
    }
    if (eventDetails.category === "") {
      setError("Please select category");
      return;
    }
    if (eventDetails.description === "") {
      setError("Please enter description");
      return;
    }
    if (eventDetails.startDate === "") {
      setError("Please enter start date");
      return;
    }
    if (eventDetails.endDate === "" && eventDetails.recurring === true) {
      setError("Please enter end date");
      return;
    }
    if (eventDetails.imageUrl.length === 0) {
      setError("Please upload image");
      return;
    }

    if (marker === null) {
      setError("Please Select location");
      return;
    }

    if (isVenue) {
      if (
        seats.length === 0 ||
        topTables.length === 0 ||
        bottomTables.length === 0 ||
        rightTables.length === 0 ||
        leftTables.length === 0
      ) {
        setError("Please Add seats to venue map");
        return;
      }
    }

    if (!isVenue) {
      if (
        seatsAvailable === 0 ||
        allSeats.reduce((acc, val) => {
          return acc + val.seatsCount;
        }, 0) === 0
      ) {
        setError("Please Add seats in seats dashboard");
        return;
      }
    }

    //upload images and submit event
    try {
      //uploading images
      setLoading(true);
      const UrlArray = [];
      for (let i = 0; i < files.length; i++) {
        const formData1 = new FormData();
        formData1.append("file", files[i]);
        const res = await axios.post(BASE_SERVER + "/upload/", formData1, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        UrlArray.push(res.data.imageUrl);
      }
      //submit event
      const body = {
        name: eventDetails.name,
        starName: eventDetails.starName,
        category: eventDetails.category,
        description: eventDetails.description,
        startDate: eventDetails.startDate,
        endDate: eventDetails.endDate,
        recurring: eventDetails.recurring,
        numberOfSeats: seats.length,
        address: location,
        taxRatio: eventDetails.taxRatio,
        types: types,
        lat: marker.lat,
        lng: marker.lng,
        venueMap: isVenue
          ? {
              seats,
              topTables,
              bottomTables,
              rightTables,
              leftTables,
              rows,
              columns,
              topObjects,
              bottomObjects,
              rightObjects,
              leftObjects,
            }
          : {},
        numberOfSeats: isVenue ? 0 : seatsAvailable,
        seatsTypes: isVenue ? [] : allSeats,
        timeSlots: eventDetails.timeSlots,
        imageUrl: UrlArray,
        numberOfAvailableSeats: seats.filter((seat) => seat.isReserved).length,
        currency: "SAR",
        provider_id: reduxState.auth?.userId,
        provider_name: JSON.parse(reduxState.auth.userData).name || "admin",
      };

      const res = await axios.post(BASE_SERVER + "/event/createEvent", body);
      console.log("res : ", res);
      if (res.status === 200) {
        setLoading(false);
        setIsOpen(false);
        //reset the form
        setEventDetails({
          name: "",
          starName: "",
          category: "sport",
          description: "",
          startDate: "",
          endDate: "",
          recurring: false,
          numberOfSeats: 0,
          address: "",
          lat: 0,
          lng: 0,
          timeSlots: [],
          imageUrl: [],
          numberOfAvailableSeats: 0,
          currency: "SAR",
          provider_id: "",
          provider_name: "",
          venueMap: {},
          seatsTypes: [],
          numberOfSeats: 0,

          ticketName: "",
          taxRatio: 0,
        });
        setFiles([]);
        setError("");
        //navigate to dashboard
        window.location.href = "/dashboard";
      }
    } catch (err) {
      console.log("err : ", err);
      setLoading(false);
      setError("Something went wrong");
    }

    setError("");
  };

  return (
    <Flex
      w="90%"
      m="0 auto"
      align="center"
      justify={"center"}
      direction="column"
    >
      <Flex w="100%" direction="column">
        <h1>New Event</h1>
        <h5>
          Please add event seats info, and then press 'Edit Event Details'
          button to add event basic details
        </h5>
      </Flex>
      <Flex direction={"column"}>
        <label>Event Name : </label>
        <input
          type="text"
          value={eventDetails.name}
          className="custom-input"
          onChange={(e) => {
            setEventDetails({ ...eventDetails, name: e.target.value });
          }}
        />
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"}>
        <label>Star Name : </label>
        <input
          type="text"
          className="custom-input"
          value={eventDetails.starName}
          onChange={(e) => {
            setEventDetails({ ...eventDetails, starName: e.target.value });
          }}
        />
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"}>
        <label>Category : </label>
        <select
          className="custom-input"
          onChange={(e) => {
            setEventDetails({ ...eventDetails, category: e.target.value });
          }}
        >
          {/* <option value="sport">Sport</option>
              <option value="education">Education</option>
              <option value="technology">Technology</option> */}
          {categories?.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"}>
        <label>Description : </label>
        <input
          className="custom-input"
          style={{ height: "200px" }}
          type="textarea"
          value={eventDetails.description}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              description: e.target.value,
            });
          }}
        />
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"}>
        <label>Tax Ratio : </label>
        <input
          className="custom-input"
          type="number"
          min={0}
          max={100}
          value={eventDetails.taxRatio}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              taxRatio: Math.max(0, Math.min(e.target.value, 100)),
            });
          }}
        />
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"} justify="center" align="center">
        <label>Recurring Event : </label>
        <input
          className="custom-input"
          type="checkbox"
          checked={eventDetails.recurring}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              recurring: e.target.checked,
            });
          }}
          style={{
            marginLeft: "10px",
            height: "20px",
            width: "20px",
          }}
        />
      </Flex>
      <Flex direction={"column"}>
        {eventDetails.recurring ? (
          <Flex direction={"column"}>
            <label>Start Date : </label>
            <input
              className="custom-input"
              type="date"
              value={eventDetails.startDate}
              onChange={(e) => {
                setEventDetails({
                  ...eventDetails,
                  startDate: e.target.value,
                });
              }}
            />
            <label>End Date : </label>
            <input
              className="custom-input"
              type="date"
              value={eventDetails.endDate}
              onChange={(e) => {
                setEventDetails({
                  ...eventDetails,
                  endDate: e.target.value,
                });
              }}
            />
          </Flex>
        ) : (
          <Flex direction={"column"}>
            <label>Start Date : </label>
            <input
              className="custom-input"
              type="date"
              value={eventDetails.startDate}
              onChange={(e) => {
                setEventDetails({
                  ...eventDetails,
                  startDate: e.target.value,
                });
              }}
            />
          </Flex>
        )}
        <hr className="bg-light mx-3"></hr>

        <div style={{ fontSize: "20px", fontWeight: "400" }}>Time Slots :</div>

        {eventDetails.timeSlots.length > 0 ? (
          <div style={{ fontSize: "18px", fontWeight: "400" }}>
            {eventDetails.timeSlots.map((timeSlot, i) => (
              <div style={{ margin: "8px" }} key={i}>
                <span>{timeSlot.startTime}</span>
                <span>{" to "}</span>
                <span>{timeSlot.endTime}</span>
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="outline-secondary"
                  onClick={(e) =>
                    setEventDetails({
                      ...eventDetails,
                      timeSlots: eventDetails.timeSlots.filter(
                        (f) =>
                          timeSlot.startTime !== f.startTime &&
                          timeSlot.endTime !== f.endTime
                      ),
                    })
                  }
                >
                  X
                </Button>
              </div>
            ))}
            <hr className="bg-light mx-3"></hr>
          </div>
        ) : (
          <>
            <div>No slots added</div>
            <hr className="bg-light mx-3"></hr>
          </>
        )}
      </Flex>
      <Flex direction={"column"}>
        <label>Start Time : </label>
        <input
          className="custom-input"
          type="time"
          value={timeSlot.startTime}
          onChange={(e) => {
            setTimeSlot({ ...timeSlot, startTime: e.target.value });
          }}
        />
        <label>End Time : </label>
        <input
          className="custom-input"
          type="time"
          value={timeSlot.endTime}
          onChange={(e) => {
            setTimeSlot({ ...timeSlot, endTime: e.target.value });
          }}
        />
        <Button
          style={{ marginLeft: "10px" }}
          variant="primary"
          onClick={addSlot}
        >
          Add Slot
        </Button>
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"}>
        <Map
          getLocation={setLocation}
          setMarker={setMarker}
          style={{ height: "230px", width: "400px", maxWidth: "90%" }}
        />
      </Flex>
      <hr className="bg-light mx-3"></hr>
      <Flex direction={"column"}>
        <label>Event Images : </label>
        <PreviewMultipleImages getFiles={getUploadedFiles} />
      </Flex>
      <div className="text-center">
        <p style={{ color: "red" }}>{error.length > 0 ? error : ""}</p>
      </div>
      <div>
        {loading ? (
          <div class="spinner-border  my-2 text-center" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* </Modal.Body> */}
      {/* <Modal.Footer> */}
      <Flex direction={"column"}>
        <EventSeatsType />
      </Flex>
      {/* <Button variant="secondary" onClick={onClose}>
        Close
      </Button> */}
      <Button
        variant="primary"
        onClick={handleAddEvent}
        size="lg"
        style={{
          marginBlock: "20px",
          fontSize: "30px",
        }}
      >
        Add Event
      </Button>
      {/* </Modal.Footer> */}
      {/* </Modal> */}
    </Flex>
  );
};

export default EventDetails;
