import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import RegularSeats from "./RegularSeats";
import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/core";
import AppContext from "../../context/appContext";

import Dashboard from "./Dashboard";
import VenuMap from "./VenuMap";
import Legend from "./Legend";
import { useContext } from "react";

const radios = [
  { name: "Venu Map", value: "1" },
  { name: "Regular Seats", value: "2" },
];

function EventSeatsType() {
  const [radioValue, setRadioValue] = useState("1");

  const { setIsVenue } = useContext(AppContext);

  useEffect(() => {
    if (radioValue === "1") {
      setIsVenue(true);
    } else {
      setIsVenue(false);
    }
  }, [radioValue]);

  return (
    <>
      <Flex direction="column" my={8} align="center" justify={"center"}>
        <h2>Chose Seats Type : </h2>
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={
                radioValue === radio.value ? "outline-success" : "outline-dark"
              }
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Flex>
      {radioValue === "1" ? (
        <>
          <Dashboard />
          <Legend />
          <VenuMap />
        </>
      ) : (
        <RegularSeats />
      )}
    </>
  );
}

export default EventSeatsType;
