import { Flex, Text } from "@chakra-ui/core";
import React, { useContext, useState } from "react";
import SeatsManager from "./SeatsManager";
import TablesManager from "./TablesManager";
import TypesManager from "./TypesManager";
import AppContext from "../../context/appContext";
import { Button } from "react-bootstrap";
import SectionsManager from "./SectionsManager";

const Dashboard = () => {
  return (
    <Flex
      direction={"column"}
      align="center"
      justify={"center"}
      w="100%"
      gap={8}
    >
      <Text fontWeight="bold" fontSize={30}>
        Event Dashboard :
      </Text>
      <Flex gap="8" direction={["column", "column", "row"]}>
        {/* <Button onClick={onOpen}>Event Details</Button>
        <EventModal
          eventModal={isOpen}
          handleEventModal={onClose}
          state={state}
        /> */}
        <SeatsManager />
        <TypesManager />
        <TablesManager />
        <SectionsManager />
      </Flex>
    </Flex>
  );
};

export default Dashboard;
