import { Flex, Text } from "@chakra-ui/core";
import React, { useContext } from "react";
import AppContext from "../../context/appContext";
import Seats from "./Seats";
import Sections from "./Sections";
import Tables from "./Tables";

import "./styles.css";

const VenuMap = () => {
  const {
    leftTables,
    rightTables,
    topTables,
    bottomTables,
    topObjects,
    leftObjects,
    rightObjects,
    bottomObjects,
  } = useContext(AppContext);

  return (
    <Flex direction={"column"} align="center" justify={"center"} mb="5">
      <Text fontSize={26} fontWeight="bold">
        Map :
      </Text>
      <Flex
        w={["350px", "600px"]}
        h="600px"
        overflow={"auto"}
        className="wrapper"
        // style={{
        //   display: "grid",
        //   // background: "#222222",
        //   // gridTemplateRows: "repeat(3, 1fr)",
        //   // gridTemplateColumns: "repeat(3, 1fr)",
        //   templateAreas: `
        //           'A1 A1 A1'
        //           'A2 B2 C2'
        //           'A3 A3 A3'
        //           `,
        //   placeItems: "center",
        //   alignItems: "center",
        //   // width: "900px",
        //   // height: "800px",
        //   padding: "10",
        //   overflow: "auto",
        //   padding: "10px",
        // }}
      >
        {/* <div></div> */}
        <div
          style={{
            gridArea: "A1",
          }}
        >
          {topTables.length > 0 && <Tables tables={topTables} />}
          {topObjects.length > 0 && <Sections sections={topObjects} />}
        </div>
        {/* <div></div> */}
        <div
          style={{
            gridArea: "A2",
          }}
        >
          {leftTables.length > 0 && (
            <Tables tables={leftTables} direction="column" />
          )}
          {leftObjects.length > 0 && (
            <Sections sections={leftObjects} direction="column" />
          )}
        </div>
        <div
          style={{
            gridArea: "B2",
          }}
        >
          <Seats />
        </div>
        <div
          style={{
            gridArea: "C2",
          }}
        >
          {rightTables.length > 0 && (
            <Tables tables={rightTables} direction="column" />
          )}
          {rightObjects.length > 0 && (
            <Sections sections={rightObjects} direction="column" />
          )}
        </div>
        {/* <div></div> */}
        <div
          style={{
            gridArea: "A3",
          }}
        >
          {bottomTables.length > 0 && <Tables tables={bottomTables} />}
          {bottomObjects.length > 0 && <Sections sections={bottomObjects} />}
        </div>
        {/* <div></div> */}
      </Flex>
    </Flex>
  );
};

export default VenuMap;
