import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

class Mapp extends React.Component {
  state = {};

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          center: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        });

        this.props.getLocation(address, latLng);
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const onClickMarker = (m, n, e) => {
      this.setState({
        center: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      });
      this.props.setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    };

    return (
      <div
        id="map"
        className=" mx-auto"
        style={{ height: "230px", width: "400px", maxWidth: "90%" }}
      >
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: this.props.address || "Search Places ...",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <div style={{ height: "150px", position: "relative" }}>
          <Map
            google={this.props.google}
            onClick={onClickMarker}
            initialCenter={this.state.center || this.props.center}
            center={this.state.center || this.props.center}
            zoom={15}
            style={{
              height: "150px",
              width: "400px",
              maxWidth: "90%",
              margin: "0 auto",
              borderRadius: "25px",
            }}
          >
            <Marker
              id={1}
              position={this.state.center || this.props.center}
              draggable={true}
              onClick={this.onMarkerClick}
            ></Marker>
          </Map>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCPdb_JA72SHSp7qgHJ7CTs9riFQZNqKH8",
  // apiKey: "AIzaSyC4cGtdqbdqcK4d9dhrOgo-Kjzy24l-R74",
})(Mapp);
