import { Flex } from "@chakra-ui/core";
import { faFan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { GiRoundTable } from "react-icons/gi";
import AppContext from "../../context/appContext";
import { MdHouse } from "react-icons/md";

function Sections({ sections, direction = "row" }) {
  const { selectedObjects, setSelectedObjects } = useContext(AppContext);

  const handleSelectedTable = (table) => {
    if (table.isReserved) {
      return;
    }

    const newselectedObjects = [...selectedObjects];
    const index = newselectedObjects.findIndex(
      (t) => t.number === table.number
    );
    if (index === -1) {
      newselectedObjects.push(table);
    } else {
      newselectedObjects.splice(index, 1);
    }
    setSelectedObjects(newselectedObjects);
  };

  return (
    <Flex
      align="center"
      justify={"center"}
      direction={direction}
      flexWrap="wrap"
      maxW={direction === "row" ? "100%" : "100px"}
      maxH={direction === "row" ? "100px" : "100%"}
      overflow={"auto"}
    >
      {sections.map((table, i) => (
        <>
          {table.isReserved ? (
            <MdHouse
              key={i}
              style={{
                marginInline: "10px",
                fontSize: "50px",
              }}
              color={"grey"}
            />
          ) : (
            // <Icon
            //   as={GiRoundTable}
            //   h="20"
            //   w="20"
            //   color={
            //     selectedObjects.some((s) => s.number === table.number)
            //       ? "yellow"
            //       : table.type?.color || "white"
            //   }
            //   key={i}
            //   cursor="pointer"
            //   onClick={() => handleSelectedTable(table)}
            // />
            <MdHouse
              key={i}
              cursor="pointer"
              style={{
                marginInline: "10px",
                fontSize: "50px",
              }}
              onClick={() => handleSelectedTable(table)}
              color={
                selectedObjects.some((s) => s.number === table.number)
                  ? "yellow"
                  : table.type?.color || "white"
              }
            />
          )}
        </>
      ))}
    </Flex>
  );
}

export default Sections;
