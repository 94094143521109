import { Flex, Text } from "@chakra-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/appContext";
import { nanoid } from "nanoid";
import { Button, Container, Modal } from "react-bootstrap";

const Positions = {
  TOP: "Top",
  BOT: "Bottom",
  RIGHT: "Right",
  LEFT: "Left",
};

const TablesManager = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const {
    topTables,
    leftTables,
    types,
    bottomTables,
    rightTables,
    setTopTables,
    setBottomTables,
    setLeftTables,
    setRightTables,
    setSelectedTables,
    selectedTables,
  } = useContext(AppContext);

  const [noTables, setNoTables] = useState(0);
  const [tableNoSeats, setTableNoSeats] = useState(0);
  const [tablePosition, setTablePosition] = useState("Top");

  const [type, setType] = useState();

  useEffect(() => {
    setType(types[0]);
  }, [types]);

  const handleTablesGeneration = () => {
    const tables = Array.from({ length: noTables }, (_, i) => {
      return {
        numberOfSeats: tableNoSeats,
        number: nanoid(10),
        totalPrice: 0,
        type: null,
        position: tablePosition,
        isReserved: false,
      };
    });
    // set tables based on position
    if (tablePosition === Positions.LEFT) {
      setLeftTables([...leftTables, ...tables]);
    }
    if (tablePosition === Positions.RIGHT) {
      setRightTables([...rightTables, ...tables]);
    }
    if (tablePosition === Positions.TOP) {
      setTopTables([...topTables, ...tables]);
    }
    if (tablePosition === Positions.BOT) {
      setBottomTables([...bottomTables, ...tables]);
    }
  };

  const reserveTables = () => {
    console.log("tables : ", selectedTables);
    selectedTables.forEach((table) => {
      if (table.position === Positions.LEFT) {
        const newLeftTables = [...leftTables];
        const index = leftTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newLeftTables[index].isReserved = true;
        }
        setLeftTables(newLeftTables);
      }
      if (table.position === Positions.RIGHT) {
        const newRightTables = [...rightTables];
        const index = rightTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newRightTables[index].isReserved = true;
        }
        setRightTables(newRightTables);
      }
      if (table.position === Positions.TOP) {
        const newTopTables = [...topTables];
        const index = topTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newTopTables[index].isReserved = true;
        }
        setTopTables(newTopTables);
      }
      if (table.position === Positions.BOT) {
        const newBottomTables = [...bottomTables];
        const index = bottomTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newBottomTables[index].isReserved = true;
        }
        setBottomTables(newBottomTables);
      }
    });
    setSelectedTables([]);
  };

  const changeTablesType = () => {
    if (!type) {
      return;
    }

    const temp = selectedTables.forEach((table) => {
      if (table.position === Positions.LEFT) {
        const newLeftTables = [...leftTables];
        const index = leftTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newLeftTables[index].type = type;
          newLeftTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setLeftTables(newLeftTables);
      }
      if (table.position === Positions.RIGHT) {
        const newRightTables = [...rightTables];
        const index = rightTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newRightTables[index].type = type;
          newRightTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setRightTables(newRightTables);
      }
      if (table.position === Positions.TOP) {
        const newTopTables = [...topTables];
        const index = topTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newTopTables[index].type = type;
          newTopTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setTopTables(newTopTables);
      }
      if (table.position === Positions.BOT) {
        const newBottomTables = [...bottomTables];
        const index = bottomTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newBottomTables[index].type = type;
          newBottomTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setBottomTables(newBottomTables);
      }
    });
    setSelectedTables([]);
  };

  const changeAllTablesType = () => {
    if (!type) {
      return;
    }

    const temp = [...leftTables, ...rightTables, ...topTables, ...bottomTables];
    temp.forEach((table) => {
      if (table.position === Positions.LEFT) {
        const newLeftTables = [...leftTables];
        const index = leftTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newLeftTables[index].type = type;
          newLeftTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setLeftTables(newLeftTables);
      }
      if (table.position === Positions.RIGHT) {
        const newRightTables = [...rightTables];
        const index = rightTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newRightTables[index].type = type;
          newRightTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setRightTables(newRightTables);
      }
      if (table.position === Positions.TOP) {
        const newTopTables = [...topTables];
        const index = topTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newTopTables[index].type = type;
          newTopTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setTopTables(newTopTables);
      }
      if (table.position === Positions.BOT) {
        const newBottomTables = [...bottomTables];
        const index = bottomTables.findIndex((t) => t.number === table.number);
        if (index !== -1) {
          newBottomTables[index].type = type;
          newBottomTables[index].totalPrice = type.price * table.numberOfSeats;
        }
        setBottomTables(newBottomTables);
      }
    });
    setSelectedTables([]);
  };

  const deleteTables = () => {
    setBottomTables([]);
    setLeftTables([]);
    setRightTables([]);
    setTopTables([]);
    setSelectedTables([]);
  };

  return (
    <>
      <Container>
        <Button onClick={onOpen} size="md">
          Tables Manager :
        </Button>
      </Container>
      <Modal show={isOpen} onHide={onClose} fullscreen="lg-down">
        <Modal.Header closeButton>
          <Modal.Title>Tables Manager : </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex gap="4" direction={"column"}>
            <Container>
              <Text>No of tables : </Text>
              <input
                type="number"
                value={noTables}
                onChange={(e) => setNoTables(parseInt(e.target.value))}
              />
            </Container>
            <Container>
              <Text>No seats : </Text>
              <Flex align="center" justify="center">
                <input
                  type="range"
                  min="0"
                  max="13"
                  value={tableNoSeats}
                  onChange={(e) => setTableNoSeats(parseInt(e.target.value))}
                />
                <p>{tableNoSeats}</p>
              </Flex>
            </Container>
            <Container>
              <Text>Position : </Text>
              <select
                value={tablePosition}
                onChange={(e) => {
                  setTablePosition(e.target.value);
                }}
              >
                {Object.values(Positions).map((pos) => (
                  <option key={pos}>{pos}</option>
                ))}
              </select>
            </Container>
          </Flex>
          <Container align={"center"} direction="column" className="gap-4">
            <Text fontSize={20} fontWeight="bold">
              Tables Selected :
            </Text>
            <Flex gap="4">
              {selectedTables.map((table) => (
                <Text key={table.number}>{table.number}</Text>
              ))}
            </Flex>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Button
                colorScheme="whatsapp"
                disabled={selectedTables.length === 0}
                w="100%"
                onClick={reserveTables}
              >
                Reserve Tables
              </Button>
              <Button
                colorScheme="orange"
                disabled={selectedTables.length === 0}
                w="100%"
                onClick={changeTablesType}
              >
                Make Tables this Type :
              </Button>
              <Button
                variant="success"
                disabled={
                  (leftTables.length === 0 &&
                    topTables.length === 0 &&
                    rightTables.length === 0 &&
                    bottomTables.length === 0) ||
                  types.length === 0
                }
                w="100%"
                onClick={changeAllTablesType}
              >
                Make All Tables this Type :
              </Button>
              <select
                value={type?.name}
                onChange={(e) => {
                  setType(types.find((t) => t.name === e.target.value));
                }}
              >
                {types.map((type, i) => (
                  <option key={i} value={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
            </Container>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button colorScheme="blue" mr={3} onClick={handleTablesGeneration}>
            Add Tables
          </Button>
          <Button variant="danger" mr={3} onClick={deleteTables}>
            Delete Tables
          </Button>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TablesManager;
