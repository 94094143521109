import { useState, useEffect, useCallback } from "react";
import AppContext from "./appContext";

const Types = {
  REGULAR: "Regular",
  ROYAL: "Royal",
  VIP: "VIP",
  SPECIAL: "Special",
  STAND: "Stand",
};

const Positions = {
  TOP: "Top",
  BOT: "Bottom",
  RIGHT: "Right",
  LEFT: "Left",
};

const AppContextProvider = ({ children }) => {
  const [isVenue, setIsVenue] = useState(false);
  //regular
  const [seatsAvailable, setSeatsAvailable] = useState(0);
  const [allSeats, setAllSeats] = useState([]);

  //venue
  const [seats, setSeats] = useState([]);
  const [topTables, setTopTables] = useState([]);
  const [bottomTables, setBottomTables] = useState([]);
  const [rightTables, setRightTables] = useState([]);
  const [leftTables, setLeftTables] = useState([]);
  const [topObjects, setTopObjects] = useState([]);
  const [bottomObjects, setBottomObjects] = useState([]);
  const [rightObjects, setRightObjects] = useState([]);
  const [leftObjects, setLeftObjects] = useState([]);
  const [types, setTypes] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);

  useEffect(() => {
    fillAllTypes();
  }, []);

  const fillAllTypes = useCallback(() => {
    //fill the types
    const typesToFill = Array.from(
      { length: Object.values(Types).length },
      (_, i) => {
        return {
          name: Object.values(Types)[i],
          price: 0,
          color: "#626262",
          type: Object.values(Types)[i],
        };
      }
    );
    setAllTypes(typesToFill);
  }, []);

  useEffect(() => {
    console.log(
      "top tables",
      topTables,
      " bottom tables",
      bottomTables,
      " right tables",
      rightTables,
      " left tables",
      leftTables
    );
  }, [leftTables, rightTables, topTables, bottomTables]);

  return (
    <AppContext.Provider
      value={{
        isVenue,
        setIsVenue,
        //regular
        seatsAvailable,
        allSeats,
        setAllSeats,
        setSeatsAvailable,
        //venue
        seats,
        rows,
        columns,
        topTables,
        bottomTables,
        rightTables,
        leftTables,
        topObjects,
        bottomObjects,
        rightObjects,
        leftObjects,
        types,
        allTypes,
        selectedSeats,
        selectedTables,
        selectedObjects,
        setSeats,
        setTopTables,
        setBottomTables,
        setRightTables,
        setLeftTables,
        setTopObjects,
        setBottomObjects,
        setRightObjects,
        setLeftObjects,
        setTypes,
        setAllTypes,
        setSelectedSeats,
        setSelectedTables,
        setSelectedObjects,
        setRows,
        setColumns,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
