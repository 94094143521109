import React, { useContext, useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import AppContext from "../../context/appContext";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Flex, IconButton, Text } from "@chakra-ui/core";

const SeatsManager = () => {
  const {
    rows,
    types,
    columns,
    seats,
    selectedSeats,
    setColumns,
    setRows,
    setSeats,
    setSelectedSeats,
  } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [type, setType] = useState();

  useEffect(() => {
    setType(types[0]);
  }, [types]);

  const generateSeats = () => {
    const array = Array.from({ length: columns * rows }, (_, i) => {
      return {
        number: i + 1,
        price: 0,
        isReserved: false,
        type: null,
      };
    });
    setSeats(array);
    onClose();
  };

  const reserveSeats = () => {
    const newSeats = [...seats];
    const temp = selectedSeats.forEach((seat) => {
      const index = newSeats.findIndex((s) => s.number === seat.number);
      if (index !== -1) {
        newSeats[index].isReserved = true;
      }
    });
    setSeats(newSeats);
    setSelectedSeats([]);
  };

  const changeType = () => {
    const newSeats = [...seats];
    selectedSeats.forEach((seat) => {
      const index = newSeats.findIndex((s) => s.number === seat.number);
      if (index !== -1) {
        newSeats[index].type = type;
      }
    });
    console.log("first", newSeats);
    setSeats(newSeats);
    setSelectedSeats([]);
  };

  const changeAllSeatsType = () => {
    const newSeats = [...seats];
    newSeats.forEach((seat) => {
      seat.type = type;
    });
    setSeats(newSeats);
  };

  const deleteSeats = () => {
    setSeats([]);
    setSelectedSeats([]);
  };

  return (
    <>
      <Container>
        <Button onClick={onOpen} size="md">
          Seats Manager :
        </Button>
      </Container>

      <Modal show={isOpen} onHide={onClose} fullscreen="lg-down">
        <Modal.Header closeButton>
          <Modal.Title>Seats Manager : </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Flex align={"center"} direction="column" gap={8}>
              <Text w="100%" fontWeight={"bold"} fontSize={15}>
                select rows :
              </Text>
              <input
                type={"number"}
                value={rows}
                onChange={(e) => {
                  setRows(parseInt(e.target.value));
                }}
              />
              <Text w="100%" fontWeight={"bold"} fontSize={15}>
                select columns :
              </Text>
              <input
                type={"number"}
                value={columns}
                onChange={(e) => {
                  setColumns(parseInt(e.target.value));
                }}
              />
              <Container align={"center"} direction="column" className="gap-4">
                <Text fontSize={20} fontWeight="bold">
                  Seats Selected :
                </Text>
                <Flex gap="4">
                  {selectedSeats?.map((seat) => (
                    <Text key={seat.number}>{seat.number}</Text>
                  ))}
                </Flex>
                <div
                  className="gap-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <Button
                    onClick={reserveSeats}
                    variant="primary"
                    disabled={selectedSeats?.length === 0}
                  >
                    Reserve Seats
                  </Button>

                  <Button
                    onClick={changeType}
                    variant="primary"
                    disabled={selectedSeats?.length === 0}
                  >
                    Make Seats this Type :
                  </Button>

                  <Button
                    onClick={changeAllSeatsType}
                    variant="success"
                    disabled={seats?.length === 0}
                  >
                    Make All Seats this Type :
                  </Button>

                  <select
                    value={type?.name}
                    onChange={(e) => {
                      setType(types.find((t) => t.name === e.target.value));
                    }}
                  >
                    {types?.map((type, i) => (
                      <option key={i} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Container>
            </Flex>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={generateSeats}>
            Generate Seats
          </Button>
          <Button variant="danger" onClick={deleteSeats}>
            Delete Seats
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SeatsManager;
