import { Box, Flex, Text } from "@chakra-ui/core";
import React, { useContext } from "react";
import AppContext from "../../context/appContext";

const Legend = () => {
  const { types } = useContext(AppContext);

  return (
    <Flex align={"center"} justify="center" my="8" gap="4" direction={"column"}>
      <Text fontSize={20} fontWeight="bold">
        Legend :
      </Text>
      <Flex
        background="#7e7e7e"
        padding={4}
        textColor="white"
        style={{
          gap: "8px",
          color: "white",
        }}
      >
        <Flex
          style={{
            gap: "8px",
          }}
        >
          <Text>Occupied</Text>
          <Box
            w="20px"
            h="20px"
            background={"#626262"}
            display="inline-block"
            borderTopLeftRadius={"10px"}
            borderTopRightRadius={"10px"}
          ></Box>
        </Flex>
        <Flex
          style={{
            gap: "8px",
          }}
        >
          <Text>N/A</Text>
          <Box
            w="20px"
            h="20px"
            background={"white"}
            display="inline-block"
            borderTopLeftRadius={"10px"}
            borderTopRightRadius={"10px"}
          ></Box>
        </Flex>
        <Flex
          style={{
            gap: "8px",
          }}
        >
          <Text>Selected</Text>
          <Box
            w="20px"
            h="20px"
            background={"yellow"}
            display="inline-block"
            borderTopLeftRadius={"10px"}
            borderTopRightRadius={"10px"}
          ></Box>
        </Flex>
        {types.map((type, i) => (
          <Flex
            style={{
              gap: "8px",
            }}
            key={i}
          >
            <Text>{type.name}</Text>
            <Box
              w="20px"
              h="20px"
              background={type.color}
              display="inline-block"
              borderTopLeftRadius={"10px"}
              borderTopRightRadius={"10px"}
            ></Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Legend;
