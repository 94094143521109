import axios from "axios";
import {
  ADD_PROMOCODE,
  GET_PROMOCODE,
  PROVIDER_CODES,
  UPDATE_PROMOCODE,
  DELETE_PROMOCODE,
  GET_PROMOCODES,
  ERROR_PROMOCODE,
  CLEAR_PROMO_ERROS,
} from "./types";
var BASE_SERVER = process.env.REACT_APP_BASE_SERVER;



//add promocode
export const addPromocode = (body) => (dispatch) => {


  axios.post(BASE_SERVER + `/promocode`, body)
    .then((res) => {
      dispatch({
        type: ADD_PROMOCODE,
        payload: res.data,
      })
    }
    )
    .catch((err) => {
      dispatch({ type: ERROR_PROMOCODE, payload: err })
    })
};




//get promocodes
export const getPromocodes = () => (dispatch) => {
  axios.get(BASE_SERVER + `/promocode/all`).then((res) => {
    dispatch({
      type: GET_PROMOCODES,
      payload: res.data,
    });
  }).catch((err) => {
    dispatch({ type: ERROR_PROMOCODE, payload: err })
  })
};

//get PROVIDER promocodes
export const getProviderPromocodes = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/promocode/provider/${id}`).then((res) => {
    dispatch({
      type: GET_PROMOCODES,
      payload: res.data,
    });
  }).catch((err) => {
    dispatch({ type: ERROR_PROMOCODE, payload: err })
  })
};

//get promocode
export const getPromocode = (code) => (dispatch) => {
  
  axios
    .get(BASE_SERVER + `/promocode/?code=${code}`)
    .then((res) =>
      dispatch({
        type: GET_PROMOCODE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: ERROR_PROMOCODE, payload: err });
    });
}

//delete promocode
export const deletePromocode = (id) => (dispatch) => {
  
  axios
    .delete(BASE_SERVER + `/promocode/${id}`)
    .then((res) =>
      dispatch({
        type: DELETE_PROMOCODE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: ERROR_PROMOCODE, payload: err });
    });
}
//update promocode
export const updatePromocode = (id, body) => (dispatch) => {
  
  axios
    .put(BASE_SERVER + `/promocode/${id}`, body)
    .then((res) =>
      dispatch({
        type: UPDATE_PROMOCODE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({ type: ERROR_PROMOCODE, payload: err });
    });
}

