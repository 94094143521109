import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addPromocode } from '../actions/promocodeActions'
import { getEvents } from '../actions/eventsActions'
import { clearErrors } from '../actions/errorActions'
import Translate from 'react-translate-component'
import Table from './CustomSelectInput/Table'
class ProviderModal extends React.Component {

    state = {
        selctedEvents: [],
        scope: '',
        successmsg: '',
        errormsg: ''
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    getSelectedEvents = (eventsList) => {
        this.setState({
            selctedEvents: [...eventsList]
        })
    }
    onSubmit = async (e) => {
        try {


            const userData = (JSON.parse(localStorage.getItem('userData')))
            const createdBy = localStorage.getItem('userType')==='admin'?'admin':userData.name
            const userId = localStorage.getItem('userId')
            const { promocode, expiry, scope, selctedEvents } = this.state

            if (promocode && expiry && scope) {
                //loop 1
                if (scope === 'exclusive' && selctedEvents.length === 0) {
                    this.setState({
                        errormsg: 'Please select at least one event'
                    })
                } else if (scope === 'exclusive' && selctedEvents.length > 0) {
                    await this.props.addPromocode({ promocode, expiry, scope, selctedEvents, createdBy, userId })
                    this.setState({
                        successmsg: 'Promocode added successfully'
                    })
                }
                else {
                    let body = {
                        promocode,
                        expiry,
                        scope,
                        createdBy,
                        userId
                    }
                    await this.props.addPromocode(body)
                    this.setState({
                        successmsg: 'Promocode added successfully'
                    })
                }
                //loop 0    
            } else {
                this.setState({
                    errormsg: 'Please fill all the fields'
                })
            }
        } catch (error) {
            this.setState({
                errormsg: 'Something went wrong'
            })
        }




    }
    componentDidMount() {
        this.props.getEvents();
    }
    handleScopeChange = (e) => {
        this.setState({
            scope: e.target.name
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render() {
        console.log(this.props.events)
        const { errors } = this.props
        return (
            <div>
                <Modal show={this.props.providerModal} onHide={this.props.handleProviderModal} style={{ color: 'purple' }}>
                    <Modal.Header closeButton >
                        <Modal.Title >Add New Promocode</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <div>
                            <div className='modal-titles'> Promocode </div>
                            <input type="text" onChange={this.onChange} name="promocode" />
                        </div>
                        <div>
                            <div className='modal-titles'> Expiry Date </div>
                            <input type="datetime-local" onChange={this.onChange} name="expiry" />
                        </div>
                        <hr className="bg-light mx-3"></hr>

                        <div>
                            <h3 className='modal-titles'> Choose a Scope </h3>

                            <div style={{ display: "flex", justifyContent: "space-around", aligItemsCenters: "center", marginTop: "30px" }}>
                                <span >
                                    <label htmlFor='global' style={{ fontWeight: "bold", marginRight: "10px" }}>
                                        Global
                                    </label>
                                    <input style={{ height: "20px", width: "20px", }} id="global" type="radio" name='global' checked={this.state.scope === "global"} onChange={this.handleScopeChange} />

                                </span>
                                <span>
                                    <label htmlFor='exclusive' style={{ fontWeight: "bold", marginRight: "10px" }}>
                                        Exclusive
                                    </label>
                                    <input style={{ height: "20px", width: "20px", }} id='exclusive' type="radio" name='exclusive' checked={this.state.scope === "exclusive"} onChange={this.handleScopeChange} />
                                </span>
                            </div>
                        </div>
                        <hr className="bg-light mx-3"></hr>

                        {(this.props.events && this.state.scope === "exclusive") ?
                            <> <div className='modal-titles'> Choose Events </div>
                                <Table events={this.props.events} getSelectedEvents={this.getSelectedEvents} />
                            </> : null}
                        <div>
                            <p className='mx-auto'
                                style={{ color: 'red' }}>{
                                    this.props.error.id === 'add provider error' ? this.props.error.msg.msg : ''}</p>
                        </div>

                        <div>
                            <p className='mx-auto text-success'>
                                {this.state.successmsg ? this.state.successmsg : ''}
                            </p>
                            <p className='mx-auto text-danger'>
                                {this.state.errormsg ? this.state.errormsg : ''}
                            </p>
                        </div>

                        <div>
                            <button onClick={this.onSubmit} className='btn btn-success my-3' style={{ background: 'purple', border: 'purple' }}><Translate content='submit' /></button>

                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

const mapState = (state) => ({
    error: state.error,
    events: localStorage.getItem('userType')==="provider"? state.eventState.providerEvents:state.eventState.events,
    errors: state.promoState.errors,


})

export default connect(mapState, { getEvents, addPromocode, clearErrors })(ProviderModal)