/* eslint-disable react/jsx-no-target-blank */
import {
  faBan,
  faCheckCircle,
  faEdit,
  faFilter,
  faPlus,
  faTrash,
  faFileDownload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import EventModal from "./eventModal";
import EditEventModal from "./editEventModal";
import CategoryModal from "./categoryModal";
import SeatsModal from "./seatsModal";
import React from "react";
import { connect } from "react-redux";
import {
  updateEvent,
  getEvents,
  deleteEvent,
  getEvent,
  clearEvent,
} from "../actions/eventsActions";
import Translate from "react-translate-component";
import store from "../store";
import { getEventBookings } from "../actions/eventsActions";
import AttendPage from "./attendPage";
import * as XLSX from "xlsx/xlsx.mjs";

class Events extends React.Component {
  state = {
    eventActive: false,
    categoryModal: false,
    seatsModal: false,
    eventModal: false,
    editEventModal: false,
    events: false,
    categories: this.props.categories,
    type: "all",
    attendTickets: false,
    bookingModel: false,
  };

  onDeactivate = (id, active, e) => {
    this.props.updateEvent(id, { active: !active });
  };
  onApprove = (id, approved, e) => {
    this.props.updateEvent(id, { approved: !approved });
  };
  onBookingClicked = (event, e) => {
    // this.props.updateEvent(id, { approved: !approved });
    let userId = localStorage.getItem("userId");
    console.log(userId);
    console.log("Event", event);
    this.setState({
      bookingModel: !this.state.bookingModel,
    });
  };

  onClickDeleteEvent = (id, e) => {
    this.props.deleteEvent(id);
  };

  onFilter = (e) => {
    let events = this.props.events;
    let type = e.target.innerText;

    let filteredEvents = events.filter((event) => {
      if (e.target.name === "eventName") {
        return event.name.indexOf(e.target.value) !== -1;
      } else if (e.target.name === "provider") {
        return event.provider_name.indexOf(e.target.value) !== -1;
      } else if (e.target.name === "date") {
        return event.date.indexOf(e.target.value) !== -1;
      } else if (e.target.name === "type") {
        return event.category.indexOf(type) !== -1;
      }
    });

    if (e.target.value || (type && type !== "all")) {
      this.setState({
        events: filteredEvents,
        type: type || "all",
      });
    } else {
      this.setState({
        events: false,
        type: type || "all",
      });
    }
  };

  onClickNewCategory = (e) => {
    this.setState({
      categoryModal: !this.state.categoryModal,
    });
  };

  onClickNewSeats = (e) => {
    this.setState({
      seatsModal: !this.state.seatsModal,
    });
  };

  onClickNewEvent = (e) => {
    // this.setState({
    //   eventModal: !this.state.eventModal,
    // });
    window.location.href = "/dashboard/newEvent";
  };

  onClickEditEvent = (id, e) => {
    this.props.getEvent(id);

    this.setState({
      editEventModal: !this.state.editEventModal,
    });
  };

  handleEventModal = () => {
    this.setState({ eventModal: false });
  };

  handleEditEventModal = () => {
    this.props.clearEvent();
    this.setState({ editEventModal: false });
  };

  handleCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  handleSeatsModal = () => {
    this.setState({ seatsModal: false });
  };
  handleBookingModal = () => {
    this.setState({ bookingModel: false });
  };
  onClickBooking = (id) => {
    this.props.getEventBookings(id);
    this.setState({
      attendTickets: true,
    });
  };
  handleProvider = (e) => {
    this.setState({
      attendTickets: false,
    });
  };
  onExportExcel = (e) => {
    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(this.props.events);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "events.xlsx");
  };
  render() {
    console.log(this.props.events);
    const isAdmin = localStorage.getItem("userType") === "admin";
    let events = this.state.events || this.props.events;
    let categories = this.state.categories;

    return (
      <div
        className="reports justify-content-center mx-auto text-center   pt-5"
        style={{ width: "95%" }}
      >
        {this.state.attendTickets ? (
          <div>
            <AttendPage handleBooking={this.handleProvider} />
          </div>
        ) : (
          <div>
            <div className="filter-row row mx-auto justify-content-center">
              <Translate
                component="input"
                attributes={{ placeholder: "event_name" }}
                onChange={this.onFilter}
                type="text"
                name="eventName"
              />
              <Translate
                component="input"
                attributes={{ placeholder: "provider" }}
                onChange={this.onFilter}
                type="text"
                name="provider"
              />
              <input onChange={this.onFilter} type="date" name="date" />

              <Dropdown style={{ margin: "0 15px" }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <Translate content="event_type" /> ({this.state.type})
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.onFilter} name="type">
                    all
                  </Dropdown.Item>
                  {categories.map((type, key = 0) => (
                    <Dropdown.Item
                      key={key++}
                      onClick={this.onFilter}
                      name="type"
                      val={type}
                    >
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <button
                className="btn btn-lg btn-success my-auto"
                style={{
                  height: "50px",
                  background: "purple",
                  border: "purple",
                }}
              >
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faFilter}
                  style={{ fontSize: "20px" }}
                />
                <Translate content="filter" />
              </button>
            </div>
            <div
              className="row justify-content-center mb-5 mx-auto"
              style={{ marginTop: "30px" }}
            >
              {/* Add Seats */}
              <button
                onClick={this.onClickNewSeats}
                className="btn btn-light mx-3 my-2 "
                style={{ color: "purple", background: "#80008017" }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <Translate content="add_seats" />
              </button>
              {/* Add Category */}
              {store.getState().auth.userType === "admin" ||
                localStorage.getItem("userType") === "admin" ? (
                <button
                  onClick={this.onClickNewCategory}
                  className="btn btn-light mx-3 my-2 "
                  style={{ color: "purple", background: "#80008017" }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <Translate content="add_category" />
                </button>
              ) : null}
              {/* add new Event */}
              <button
                onClick={this.onClickNewEvent}
                className="btn btn-light mx-3 my-2"
                style={{ color: "purple", background: "#80008017" }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <Translate content="add_new_event" />
              </button>
              <button
                onClick={this.onExportExcel}
                className="btn btn-light mx-3 my-2"
                style={{ color: "purple", background: "#80008017" }}
              >
                <FontAwesomeIcon icon={faDownload} />
                <div>Export as excel</div>
              </button>
            </div>

            <EventModal
              eventModal={this.state.eventModal}
              handleEventModal={this.handleEventModal}
            />
            <EditEventModal
              editEventModal={this.state.editEventModal}
              handleEditEventModal={this.handleEditEventModal}
            />
            <CategoryModal
              categoryModal={this.state.categoryModal}
              handleCategoryModal={this.handleCategoryModal}
            />
            <SeatsModal
              seatsModal={this.state.seatsModal}
              handleSeatsModal={this.handleSeatsModal}
            />
            <SeatsModal
              seatsModal={this.state.bookingModel}
              handleSeatsModal={this.handleBookingModal}
            />

            {/** events */}

            <div
              className="card shadow mx-auto text-center "
              style={{
                width: "95%",
                height: "800px",
                color: "purple",
                overflow: "auto",
              }}
            >
              <table className="mt-3">
                <thead
                  className="mx-auto "
                  style={{ borderBottom: ".5px solid #ff5e00 " }}
                >
                  <tr>
                    {/* <th>
                      <Translate content="image" />
                    </th> */}
                    {store.getState().auth.userType !== "provider" ? (
                      <th>
                        <Translate content="provider_name" />
                      </th>
                    ) : null}
                    <th>
                      <Translate content="event_name" />
                    </th>
                    <th>
                      <Translate content="event_type" />
                    </th>
                    <th>
                      <Translate content="star_name" />
                    </th>
                    {/* <th>
                      <Translate content="event_date" />
                    </th> */}
                    {/* <th>
                      <Translate content="seats_count" />
                    </th> */}
                    <th>
                      <Translate content="booked_seats" />
                    </th>
                    {/* <th>
                      <Translate content="address" />
                    </th> */}
                    <th>
                      <Translate content="attended_count" />
                    </th>
                    <th>
                      <Translate content="empty" />
                    </th>
                    {/* <th><Translate content="empty" /></th> */}
                    <th>
                      <Translate content="empty" />
                    </th>
                    <th>
                      <Translate content="download_qr" />
                    </th>
                    <th>Approval action</th>
                    <th>Book manually</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event, key = 0) => (
                    <tr
                      key={key++}
                      style={{ borderBottom: "1px solid #ffa50040" }}
                    >
                      {/* <td>
                        <img
                          alt="img"
                          className=" my-3"
                          src={event.imageUrl[0]}
                          width="100"
                          height="70"
                          style={{ borderRadius: '18px' }}
                        />
                      </td> */}
                      {store.getState().auth.userType !== "provider" ? (
                        <td>{event.provider_name}</td>
                      ) : null}
                      <td>{event.name}</td>
                      <td>{event.category}</td>
                      <td>{event.starName}</td>
                      {/* <td style={{ width: '150px' }}>{event.date}</td> */}
                      {/* <td>{event.numberOfAvailableSeats}</td> */}

                      <td>
                        {/* <p style={{ margin: "0" }}>{event.bookedSeats || 0}</p> */}
                        <a
                          href="#"
                          onClick={this.onClickBooking.bind(this, event._id)}
                        >
                          <Translate content="display_details" />
                        </a>
                      </td>
                      {/* <td className="py-2">{event.address}</td> */}

                      <td>
                        {event.attendCount ? (
                          <div>
                            <span>- {event.attendCount} - </span>
                            <a
                              href="#"
                              onClick={this.onClickBooking.bind(
                                this,
                                event._id
                              )}
                            >
                              <Translate content="display_details" />
                            </a>
                          </div>
                        ) : (
                          0
                        )}
                      </td>

                      <td>
                        {" "}
                        <button
                          onClick={this.onClickEditEvent.bind(this, event._id)}
                          id="btn-light"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </td>
                      {/* <td>
                        {' '}
                        <button onClick={this.onDeactivate.bind(this, event._id, event.active)} id="btn-light">
                          <FontAwesomeIcon icon={event.active ? faCheckCircle : faBan} />
                        </button>
                      </td> */}

                      <td>
                        {" "}
                        <button
                          onClick={this.onClickDeleteEvent.bind(
                            this,
                            event._id
                          )}
                          id="btn-light"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "inherit",
                            fontSize: "20px",
                          }}
                        >
                          <a
                            className="qr-download-link"
                            href={`/events/${event._id}/downloadqr`}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faFileDownload}>
                              {" "}
                            </FontAwesomeIcon>{" "}
                          </a>
                        </button>
                      </td>
                      {isAdmin ? (
                        <td>
                          <button
                            className="btn btn-light mx-3 my-2 "
                            style={{ color: "purple", background: "#80008017" }}
                            onClick={this.onApprove.bind(
                              this,
                              event._id,
                              event.approved
                            )}
                          >
                            {event.approved ? "Disapprove" : "Approve"}
                          </button>
                        </td>
                      ) : event.approved ? (
                        <td>Approved</td>
                      ) : (
                        <td>Pending</td>
                      )}
                      <td>
                        <button
                          className="btn btn-light mx-3 my-2 "
                          style={{ color: "purple", background: "#80008017" }}
                        >
                          <a
                            className="qr-download-link"
                            href={`https://landing.toneap.com/events/${event._id}?isAdmin=true`}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faFileDownload}>
                              {" "}
                            </FontAwesomeIcon>{" "}
                          </a>
                          {/* Book */}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.eventState.events,
  categories: state.eventState.categories,
});

export default connect(mapStateToProps, {
  updateEvent,
  getEvents,
  deleteEvent,
  getEvent,
  clearEvent,
  getEventBookings,
})(Events);
