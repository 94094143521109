import { useState } from "react";
import ImagesGallery from "./ImagesGallery";
function PreviewMultipleImages({getFiles}){
   const [images, setImages] = useState([]);
   
   const handleMultipleImages =(evnt)=>{
      const selectedFIles =[];
      const targetFiles =evnt.target.files;
      getFiles(targetFiles);
      const targetFilesObject= [...targetFiles]
      targetFilesObject.map((file)=>{
         return selectedFIles.push(URL.createObjectURL(file))
      })
     
      setImages(selectedFIles);
    }
    
return(
    <>
    <div className="form-group my-3 mx-3">
    <input style={{border:"none", borderRadius:"0px" }} type="file" onChange={handleMultipleImages} multiple/>
    </div>
      <ImagesGallery images={images} />
   
   </>
)
}
export default PreviewMultipleImages