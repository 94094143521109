import { Text, Flex } from "@chakra-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import TypesTable from "./TypesTable";

import AppContext from "../../context/appContext";

const Types = {
  REGULAR: "Regular",
  ROYAL: "Royal",
  VIP: "VIP",
  SPECIAL: "Special",
  STAND: "Stand",
};

const TypesManager = () => {
  const { types, allTypes, setTypes, setAllTypes } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [availableTypes, setAvailableTypes] = useState([]);
  const [newTypeData, setNewTypeData] = useState({
    name: "",
    price: 0,
    color: "#626262",
    type: Types.REGULAR,
  });

  useEffect(() => {
    const filteredTypes = allTypes.filter(
      (type) => !types.some((t) => t.type === type.type)
    );
    setAvailableTypes(filteredTypes);
  }, [allTypes, types]);

  const onAddType = () => {
    const newTypes = [...types, newTypeData];
    setTypes(newTypes);
    setNewTypeData({
      name: "",
      price: 0,
      color: "#626262",
      type: availableTypes[0].type,
    });
  };

  return (
    <>
      <Container>
        <Button onClick={onOpen} variant="primary" size="md">
          Types Manager :
        </Button>
      </Container>
      <Modal show={isOpen} onHide={onClose} fullscreen="lg-down">
        <Modal.Header closeButton>
          <Modal.Title>Types Manager : </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex direction={"column"}>
            <Container>
              <Text>Name : </Text>
              <input
                value={newTypeData?.name}
                onChange={(e) => {
                  setNewTypeData({ ...newTypeData, name: e.target.value });
                }}
              />
            </Container>
            <Container>
              <Text>Type : </Text>
              <select
                onChange={(e) =>
                  setNewTypeData({
                    ...newTypeData,
                    type: e.target.value,
                  })
                }
              >
                {availableTypes.map((type, i) => (
                  <option key={i} value={type.type}>
                    {type.type}
                  </option>
                ))}
              </select>
            </Container>
            <Container>
              <Text>Price : </Text>
              <input
                type="number"
                value={newTypeData?.price}
                onChange={(e) => {
                  setNewTypeData({
                    ...newTypeData,
                    price: parseInt(e.target.value),
                  });
                }}
              />
            </Container>
            <Container>
              <Text>Color : </Text>
              <input
                type="color"
                value={newTypeData?.color}
                onChange={(e) => {
                  setNewTypeData({ ...newTypeData, color: e.target.value });
                }}
              />
            </Container>
          </Flex>
          <Flex direction={"column"} gap="8">
            {types.length !== 0 && <TypesTable />}
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onAddType}>
            Add Type
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TypesManager;
