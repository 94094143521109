import { Flex, Text } from "@chakra-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/appContext";
import { nanoid } from "nanoid";
import { Button, Container, Modal } from "react-bootstrap";

const Positions = {
  TOP: "Top",
  BOT: "Bottom",
  RIGHT: "Right",
  LEFT: "Left",
};

function SectionsManager() {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const {
    topObjects,
    leftObjects,
    types,
    bottomObjects,
    rightObjects,
    setTopObjects,
    setBottomObjects,
    setLeftObjects,
    setRightObjects,
    setSelectedObjects,
    selectedObjects,
  } = useContext(AppContext);

  const [noObjects, setNoObjects] = useState(0);
  const [sectionName, setSectionName] = useState("");
  const [sectionsNoSeats, setsectionsNoSeats] = useState(0);
  const [sectionsPosition, setsectionsPosition] = useState("Top");

  const [type, setType] = useState();

  useEffect(() => {
    setType(types[0]);
  }, [types]);

  const handleObjectsGeneration = () => {
    const Objects = Array.from({ length: noObjects }, (_, i) => {
      return {
        numberOfSeats: sectionsNoSeats,
        number: nanoid(10),
        totalPrice: 0,
        name: sectionName,
        type: null,
        position: sectionsPosition,
        isReserved: false,
      };
    });
    // set Objects based on position
    if (sectionsPosition === Positions.LEFT) {
      setLeftObjects([...leftObjects, ...Objects]);
    }
    if (sectionsPosition === Positions.RIGHT) {
      setRightObjects([...rightObjects, ...Objects]);
    }
    if (sectionsPosition === Positions.TOP) {
      setTopObjects([...topObjects, ...Objects]);
    }
    if (sectionsPosition === Positions.BOT) {
      setBottomObjects([...bottomObjects, ...Objects]);
    }
  };

  const reserveObjects = () => {
    console.log("Objects : ", selectedObjects);
    selectedObjects.forEach((sections) => {
      if (sections.position === Positions.LEFT) {
        const newLeftObjects = [...leftObjects];
        const index = leftObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newLeftObjects[index].isReserved = true;
        }
        setLeftObjects(newLeftObjects);
      }
      if (sections.position === Positions.RIGHT) {
        const newRightObjects = [...rightObjects];
        const index = rightObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newRightObjects[index].isReserved = true;
        }
        setRightObjects(newRightObjects);
      }
      if (sections.position === Positions.TOP) {
        const newTopObjects = [...topObjects];
        const index = topObjects.findIndex((t) => t.number === sections.number);
        if (index !== -1) {
          newTopObjects[index].isReserved = true;
        }
        setTopObjects(newTopObjects);
      }
      if (sections.position === Positions.BOT) {
        const newBottomObjects = [...bottomObjects];
        const index = bottomObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newBottomObjects[index].isReserved = true;
        }
        setBottomObjects(newBottomObjects);
      }
    });
    setSelectedObjects([]);
  };

  const changeObjectsType = () => {
    if (!type) {
      return;
    }

    const temp = selectedObjects.forEach((sections) => {
      if (sections.position === Positions.LEFT) {
        const newLeftObjects = [...leftObjects];
        const index = leftObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newLeftObjects[index].type = type;
          newLeftObjects[index].totalPrice =
            type.price * sections.numberOfSeats;
        }
        setLeftObjects(newLeftObjects);
      }
      if (sections.position === Positions.RIGHT) {
        const newRightObjects = [...rightObjects];
        const index = rightObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newRightObjects[index].type = type;
          newRightObjects[index].totalPrice =
            type.price * sections.numberOfSeats;
        }
        setRightObjects(newRightObjects);
      }
      if (sections.position === Positions.TOP) {
        const newTopObjects = [...topObjects];
        const index = topObjects.findIndex((t) => t.number === sections.number);
        if (index !== -1) {
          newTopObjects[index].type = type;
          newTopObjects[index].totalPrice = type.price * sections.numberOfSeats;
        }
        setTopObjects(newTopObjects);
      }
      if (sections.position === Positions.BOT) {
        const newBottomObjects = [...bottomObjects];
        const index = bottomObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newBottomObjects[index].type = type;
          newBottomObjects[index].totalPrice =
            type.price * sections.numberOfSeats;
        }
        setBottomObjects(newBottomObjects);
      }
    });
    setSelectedObjects([]);
  };

  const changeAllObjectsType = () => {
    if (!type) {
      return;
    }

    const temp = [
      ...leftObjects,
      ...rightObjects,
      ...topObjects,
      ...bottomObjects,
    ];
    temp.forEach((sections) => {
      if (sections.position === Positions.LEFT) {
        const newLeftObjects = [...leftObjects];
        const index = leftObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newLeftObjects[index].type = type;
          newLeftObjects[index].totalPrice =
            type.price * sections.numberOfSeats;
        }
        setLeftObjects(newLeftObjects);
      }
      if (sections.position === Positions.RIGHT) {
        const newRightObjects = [...rightObjects];
        const index = rightObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newRightObjects[index].type = type;
          newRightObjects[index].totalPrice =
            type.price * sections.numberOfSeats;
        }
        setRightObjects(newRightObjects);
      }
      if (sections.position === Positions.TOP) {
        const newTopObjects = [...topObjects];
        const index = topObjects.findIndex((t) => t.number === sections.number);
        if (index !== -1) {
          newTopObjects[index].type = type;
          newTopObjects[index].totalPrice = type.price * sections.numberOfSeats;
        }
        setTopObjects(newTopObjects);
      }
      if (sections.position === Positions.BOT) {
        const newBottomObjects = [...bottomObjects];
        const index = bottomObjects.findIndex(
          (t) => t.number === sections.number
        );
        if (index !== -1) {
          newBottomObjects[index].type = type;
          newBottomObjects[index].totalPrice =
            type.price * sections.numberOfSeats;
        }
        setBottomObjects(newBottomObjects);
      }
    });
    setSelectedObjects([]);
  };

  const deleteSections = () => {
    setSelectedObjects([]);
    setRightObjects([]);

    setLeftObjects([]);
    setTopObjects([]);
    setBottomObjects([]);
  };

  return (
    <>
      <Container>
        <Button onClick={onOpen} size="md">
          Sections Manager :
        </Button>
      </Container>
      <Modal show={isOpen} onHide={onClose} fullscreen="lg-down">
        <Modal.Header closeButton>
          <Modal.Title>Secions Manager : </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex gap="4" direction={"column"}>
            <Container>
              <Text>No of Sections : </Text>
              <input
                type="number"
                value={noObjects}
                onChange={(e) => setNoObjects(parseInt(e.target.value))}
              />
            </Container>
            <Container>
              <Text>Section Name : </Text>
              <input
                type="text"
                value={sectionName}
                onChange={(e) => setSectionName(e.target.value)}
              />
            </Container>
            <Container>
              <Text>No seats : </Text>
              <Flex align="center" justify="center">
                <input
                  type="range"
                  min="0"
                  max="20"
                  value={sectionsNoSeats}
                  onChange={(e) => setsectionsNoSeats(parseInt(e.target.value))}
                />
                <p>{sectionsNoSeats}</p>
              </Flex>
            </Container>
            <Container>
              <Text>Position : </Text>
              <select
                value={sectionsPosition}
                onChange={(e) => {
                  setsectionsPosition(e.target.value);
                }}
              >
                {Object.values(Positions).map((pos) => (
                  <option key={pos}>{pos}</option>
                ))}
              </select>
            </Container>
          </Flex>
          <Container align={"center"} direction="column" className="gap-4">
            <Text fontSize={20} fontWeight="bold">
              Sections Selected :
            </Text>
            <Flex gap="4">
              {selectedObjects.map((sections) => (
                <Text key={sections.number}>{sections.number}</Text>
              ))}
            </Flex>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Button
                colorScheme="whatsapp"
                disabled={selectedObjects.length === 0}
                w="100%"
                onClick={reserveObjects}
              >
                Reserve Sections
              </Button>
              <Button
                colorScheme="orange"
                disabled={selectedObjects.length === 0}
                w="100%"
                onClick={changeObjectsType}
              >
                Make Sections this Type :
              </Button>
              <Button
                variant="success"
                disabled={
                  (leftObjects.length === 0 &&
                    topObjects.length === 0 &&
                    rightObjects.length === 0 &&
                    bottomObjects.length === 0) ||
                  types.length === 0
                }
                w="100%"
                onClick={changeAllObjectsType}
              >
                Make All Sections this Type :
              </Button>
              <select
                value={type?.name}
                onChange={(e) => {
                  setType(types.find((t) => t.name === e.target.value));
                }}
              >
                {types.map((type, i) => (
                  <option key={i} value={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
            </Container>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button colorScheme="blue" mr={3} onClick={handleObjectsGeneration}>
            Add Sections
          </Button>
          <Button variant="danger" mr={3} onClick={deleteSections}>
            Delete Sections
          </Button>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SectionsManager;
