import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Login from "./components/login";
import Home from "./components/home";
import NewEvent from "./components/NewEventPage";
import Dashboard from "./components/dashboard";
import counterpart from "counterpart";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import english from "./components/lang/en";
import arabic from "./components/lang/ar";
import Terms from "./components/terms";
import BookingPage from "./components/bookingPage/index";
import EventListPage from "./components/eventListPage";
import GenerateTicket from "./components/generateTicket/index";
import EventQrDownload from "./components/eventQrCodeDownload/index";

counterpart.registerTranslations("english", english);
counterpart.registerTranslations("arabic", arabic);

counterpart.setLocale(
  localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english"
);

class App extends React.Component {
  componentDidMount() { }

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Router>
            <Route exact path="/" component={() => {
              window.location.href = 'https://landing.toneap.com';
              return null;
            }} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/dashboard/newEvent" component={NewEvent} />
            <Route exact path="/terms" component={Terms} />
            {/* <Route exact path="/events" component={EventListPage} /> */}
            <Route exact path="/events/:id" component={BookingPage} />
            <Route
              exact
              path="/booking/:id/success"
              component={GenerateTicket}
            />
            <Route
              exact
              path="/events/:id/downloadqr"
              component={EventQrDownload}
            />
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
