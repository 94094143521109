import { Box } from "@chakra-ui/core";
import { useContext } from "react";
import AppContext from "../../context/appContext";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";

const TypesTable = () => {
  const { types, setTypes } = useContext(AppContext);

  const onDeleteType = (type) => {
    const newTypes = types.filter((t) => t.type !== type.type);
    setTypes(newTypes);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name </th>
          <th>Price</th>
          <th>Type</th>
          <th>Color</th>
        </tr>
      </thead>
      <tbody>
        {types.map((type) => (
          <tr key={type.type}>
            <td>{type.name}</td>
            <td>{type.price}</td>
            <td>{type.type}</td>
            <td>
              <Box background={type.color} h="20px" w="20px"></Box>
            </td>
            <td>
              <Button onClick={() => onDeleteType(type)}>Delete</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TypesTable;
