import { Flex } from "@chakra-ui/core";
import Translate from "react-translate-component";
import { Dropdown } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import AppContext from "../../context/appContext";

function RegularSeats() {
  const { setSeatsAvailable, setAllSeats, allSeats } = useContext(AppContext);

  const [seatsTypes, setSeatsTypes] = useState([]);
  const [seatsCount, setSeatsCount] = useState(0);

  useEffect(() => {
    const getSeatsTypes = async () => {
      const seatsTypes = await axios.get(
        process.env.REACT_APP_BASE_SERVER + "/seats"
      );

      setSeatsTypes(seatsTypes.data.seats);
    };
    getSeatsTypes();
    setAllSeats([{ seatsType: "", seatsCount: 0, seatsPrice: 0 }]);
  }, []);

  const onAddMoreSeats = () => {
    setAllSeats((prev) => [
      ...prev,
      { seatsType: "", seatsCount: 0, seatsPrice: 0 },
    ]);
  };

  return (
    <Flex direction={"column"} align="center" justify={"center"} gap={8}>
      <h1> Seats Dashboard : </h1>
      <div className="d-flex justify-content-center my-4">
        <div>
          <div>
            <label className="px-3">
              <Translate content="seats_count" />
            </label>
          </div>
          <input
            onChange={(e) => {
              setSeatsCount(e.target.value);
              setSeatsAvailable(e.target.value);
            }}
            value={seatsCount}
            name="numberOfSeats"
            type="number"
            className="mx-2"
            style={{ width: "100px" }}
          />
        </div>
      </div>
      {allSeats.map((seat, index) => {
        return (
          <>
            <div
              className="d-flex justify-content-center my-4"
              style={{
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <label classNmae="px-3">
                  <Translate content="seat_type" />{" "}
                </label>
                <Dropdown style={{ width: "120px" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic2"
                    style={{ overflow: "hiddin" }}
                  >
                    {allSeats[index].seatsType}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {seatsTypes.length &&
                      seatsTypes?.map((type, key = 0) => (
                        <Dropdown.Item
                          onClick={() => {
                            setAllSeats((prev) => {
                              const newSeats = [...prev];
                              newSeats[index].seatsType = type;
                              return newSeats;
                            });
                          }}
                          key={key++}
                        >
                          {type}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div>
                <div>
                  <label classNmae="px-3">
                    <Translate content="seat_price" /> (SAR)
                  </label>
                </div>
                <input
                  onChange={(e) =>
                    setAllSeats((prev) => {
                      const newSeats = [...prev];
                      newSeats[index].seatsPrice = e.target.value;
                      return newSeats;
                    })
                  }
                  name="priceForOneSeat"
                  type="number"
                  step="0.01"
                  className="mx-2"
                  style={{ width: "100px" }}
                />
              </div>

              <div>
                <div>
                  <label classNmae="px-3">
                    <Translate content="seat_count" />{" "}
                  </label>
                </div>
                <input
                  onChange={(e) => {
                    setAllSeats((prev) => {
                      const newSeats = [...prev];
                      newSeats[index].seatsCount = e.target.value;

                      return newSeats;
                    });
                  }}
                  value={allSeats[index].seatsCount}
                  name="seatCount"
                  type="number"
                  className="mx-2"
                  style={{ width: "100px" }}
                />
              </div>
              <button
                className="btn btn-sm btn-outline-danger mb-2"
                onClick={() => {
                  setAllSeats((prev) => {
                    const newSeats = [...prev];
                    newSeats.splice(index, 1);
                    return newSeats;
                  });
                }}
              >
                x Delete
              </button>
            </div>
          </>
        );
      })}
      <div>
        <button
          onClick={onAddMoreSeats}
          style={{
            background: "none",
            border: "none",
            color: "purple",
          }}
        >
          + Add more
        </button>
      </div>
    </Flex>
  );
}

export default RegularSeats;
